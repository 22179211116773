* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}


header {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: black;
  color: rgb(218, 181, 140);
  height: 13vh;
  border-bottom: 1px solid white;
}

.H-1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.S-H-1 {
  font-size: 1vw;
  height: 100%;
}

.dropdown-toggle {
  color: white;
  background-color: black;
  border: none;
}

.dropdown-toggle:hover {
  background-color: white;
  color: black;
}

.dropdown-toggle:focus {
  background-color: black;
  border: none
}

.dropdown-toggle:active {
  background-color: black;
  color: black;
}

.carousel-content {
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  filter: drop-shadow(0px 8px 8px #222);
}

.dropdown {
  display: flex;
  align-items: flex-end;
  width: 5%;
}

.dropdown-menu {
  background-color: white;
  margin-top: -3px;
}

.dropdown-item {
  color: black;
}

.dropdown-item:hover {
  background-color: black;
  color: white;
}


.logo {
  width: 35vw;
}

.nav-link {
  text-decoration: none;
  color: rgb(218, 181, 140);
}

.nav-link:hover {
  color: black;
}

.nav-linkactive {
  color: grey;
  text-decoration: none;
}

ul {
  list-style-type: none;
}



.carousel-words {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 50%;
  height: fit-content;
  background-color: rgb(141, 141, 141, 0.5);
  position: absolute;
  top: 80%;
  left: 25%;
  font-size: 1.6vw;
}

.carousel-control-prev-icon {
  background-image: url('../public/Pics/Icons/left-arrow.png');
}

.carousel-control-next:hover {
  opacity: 1;

}

.carousel-control-next-icon {
  background-image: url('../public/Pics/Icons/right-arrow.png');
}


.G-H-I {
  display: flex;
  align-items: center;
  width: 15vw;
  flex-direction: column;
}


.F-cont {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  background-color: black;
  padding-bottom: 1%;
  padding-top: 1%;
}

.F-CI {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  width: fit-content;
}

.Instalink {
  display: flex;
  width: 10%;
}

.Insta {
  width: 100%;
}

.contact-H {
  width: 100%;
  border-bottom: solid 1px white;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: grey;
}

.A-C-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

}

.A-C-2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-top: 5%;
  border-top: 1px white solid;
}

.about-all {
  display: flex;
  flex-direction: column;
  border-bottom: 1px white solid;
}

.core {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 5%;
}

.core-H {
  display: flex;
  height: 20vw;
  width: 33%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  border: 1px white solid;

}

.C-text {
  width: 100%;
  text-align: center;
  padding-bottom: 3%;
}


.c-Img-H {
  width: 40%;
  padding: 5%;
}

.A-C {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-around;
}

.Atext {
  display: flex;
  width: 90%;
  text-align: center;

}


.Home-G-L {
  background-color: rgb(71, 71, 71);
  color: white;
}


.Gal-Pics {
  color: white;
  border: 1px white solid;
}

.remodel {
  display: flex;

}


.Gal-Pics:hover {
  color: rgb(218, 181, 140);
  border: 1px rgb(218, 181, 140) solid;
}

.Home-header {
  text-align: center;
  font-size: 50px;
}

.P-S-H {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
}

.P-S-I {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35vw;
}

.P-S {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid white;
}

.remodel-P-S {
  width: 50%;
  height: 35vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid white;
}


@media screen and (max-width: 800px) {
  header {
    height: fit-content;
  }

  .dropdown {
    justify-content: center;
  }

  .A-C {
    flex-direction: column;

  }

  .carousel {
    height: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .F-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .core {
    flex-direction: column;
    width: 100%;
  }

  .core-H {
    width: 100%;
    height: fit-content;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .H-1 {
    flex-direction: column;
    align-items: center;
  }

  .nav-tab {
    font-size: 5vw;
  }

  .logo {
    margin-top: 15px;
    width: 100%;
  }

  .carousel {
    padding-left: 0px;
    padding-right: 0px;
  }

  .carousel-words {
    font-size: 2.2vw;
    top: 70%;
  }

  .Home-G-L {
    height: 45vw;
    font-size: medium;
    height: max-content;
  }

  .Home-header {
    font-size: 30px;
  }

  .P-S {
    width: 100%;

  }

  .remodel {
    display: flex;
    flex-direction: column-reverse;
  }

  .remodel-P-S {
    width: 100%;
    height: min-content;
  }



  .P-S-I {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
  }

}